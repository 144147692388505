<style>
i.mw-20{
  min-width: 20px;
}
#magazineForm .form-label{
  font-weight: bold;
}
.custom-control-label{
  font-weight: bold !important;
}

</style>
<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <b-overlay :show="showLoader" no-wrap></b-overlay>
        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
          <div class="card">
            <div class="card-body table-responsive table-head pt-2">
              <div class="row">
                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                  <h1 class="m-0 text-dark pt-2 text-left px-0">Magazines</h1>
                </div>
                <div class="col-md-4 bor-bot pr-0">
                  <div class="text-right pb-3 pt-1">
                    <ol class="breadcrumb float-sm-right pr-0">
                      <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                      <li class="breadcrumb-item"><Breadcrumbs/></li>
                    </ol>
                  </div>
                </div>
              </div>
              <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>

              <div class="row">
                <div class="col-md-6 mt-3 showentries pl-0">
                </div>
                <div class="col-md-6 pr-0">
                  <div class="mt-3 mb-3 text-right pr-0 emltmpserchsec">
                    <div class=" card-text d-flex align-items-center float-right mb-0">
                      <div>
                        <a href="#" v-show="loadCompleted" @click="addMagazine()"  class="btn btn darkblubtn btn-outline-primary btn-outline-primary">Add Magazine</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-0 mt-3 faqsearchsec faqseclisttable">
                  <table class="table table-sm table-bordered table-hover mx-0" style="width:100%;" id="magazineList">
                    <thead class="thead-dark">
                    <tr>
                      <th class="d-none">Id</th>
                      <th width="40%">Title</th>
                      <th width="40%">URL</th>
                      <th width="20%">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    <tr>
                      <td colspan="6">&nbsp;</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <b-modal
                    id="edit-modal"
                    :title="editModal.title"
                    size="lg"
                    ref="modal"
                    @ok.prevent="handleOk(editModal.function)"
                    @hide="resetEditModal"
                >
                  <b-overlay :show="formLoader" no-wrap></b-overlay>
                  <form ref="form" @submit.stop.prevent="handleSubmit" id="magazineForm">
                    <div class="row">
                      <div class="col-md-12">
                        <div>
                          <label class="pt-4 form-label" for="feedback-name"
                          >Title<sup class="text-danger">*</sup>
                          </label
                          >
                          <b-form-input
                              @keydown="magazineErrors.clear('title')"
                              v-model="magazine.title"
                              :class="magazineErrors.has('title') ? 'is-invalid' : ''"
                              id="feedback-name"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!magazineErrors.has('title')"
                          >
                            {{ magazineErrors.get("title") }}</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="pt-2">
                          <label class="pt-4 form-label" for="feedback-name"
                          >Url<sup class="text-danger">*</sup></label
                          >
                          <b-form-input
                              @keydown="magazineErrors.clear('url')"
                              v-model="magazine.url"
                              :class="magazineErrors.has('url') ? 'is-invalid' : ''"
                              id="feedback-name"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!magazineErrors.has('url')"
                          >
                            {{ magazineErrors.get("url") }}</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </b-modal>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import axios from "axios";
import Errors from "../../Errors";
import CustomAlert from "../CustomAlert";

export default {
  name:"cmsMagazine",
  components:{
    CustomAlert
  },
  data() {
    return {
      table:'',
      alertMessage: "",
      displayAlert: false,
      loadCompleted: false,
      magazine:{
        title:'',
        url:''
      },
      editModal:{
        title:'Add Magazines',
      },
      magazineErrors: new Errors(),
      formLoader:false
    }
  },
  methods:{

    addMagazine(){
      this.magazineErrors.clear();
      this.editModal.title = 'Add Magazine';
      this.editModal.function = 'magazineCreate';
      this.clearMagazine();
      this.$root.$emit("bv::show::modal", "edit-modal");
    },
    validate(){
      let valid = true;
      const errors = {};
      if (this.magazine.title == "") {
        errors.title = ["This Field is required"];
        valid = false;
      }
      if (this.magazine.url == "") {
        errors.url = ["This Field is required"];
        valid = false;
      } else if (!/(https?:\/\/)?([\da-z.-]+)\.([a-z]{2,6})([/\w.-]*)*\/?/.test(this.magazine.url)) {
        errors.url = ["Invalid URL format"];
        valid = false;
      }
      this.magazineErrors.record(errors);
      return valid;
    },
    magazineCreate() {
      if (this.validate()) {
        this.formLoader = true;
        let formData = new FormData();
        formData.append('title', this.magazine.title);
        formData.append('url', this.magazine.url);

        axios.post(process.env.VUE_APP_URL + "api/magazines/store", formData
            , {
              headers:this.adminHeaders
            }).then(response => {
          if (response.data.status == 'success') {
            this.$root.$emit("bv::hide::modal", "edit-modal");
            this.table.ajax.reload();
            this.formLoader = false;
            this.displayAlert = true;
            this.alertMessage = "Successfully Updated";
            console.log(response);
          } else {
            console.log(response);
          }
        }).catch(error => {
          this.onFail(error.response.data.errors);
          this.formLoader = false;
        });
      }
    },
    magazineEdit(){
      if (this.validate()) {
        this.formLoader = true;
        let formData = new FormData();
        formData.append('title', this.magazine.title);
        formData.append('url', this.magazine.url);

        axios.post(process.env.VUE_APP_URL + "api/magazines/update/" + this.magazine.id, formData
            , {
              headers: this.adminHeaders
            }).then(response => {
          if (response.data.status == 'success') {
            this.$root.$emit("bv::hide::modal", "edit-modal");
            this.table.ajax.reload();
            this.formLoader = false;
            this.displayAlert = true;
            this.alertMessage = "Successfully Updated";
            console.log(response);
          } else {
            console.log(response);
          }
        }).catch(error => {
          this.formLoader = false;
          this.onFail(error.response.data.errors);
        });
      }
    },
    handleOk(process){
      this[process]();
    },
    onFail(errors){
      alert(errors);
      this.magazineErrors.record(errors);
    },
    clearMagazine(){
      this.magazine.title = '';
      this.magazine.url = '';
    },
    magazineType(){
      this.magazineErrors.clear();
    }
  },
  mounted(){
    var self=this;
    this.$set(this.adminHeaders, 'Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    this.table = $("#magazineList").DataTable({
      dom: 'lfrtip',
      "bFilter": true,
      processing: true,
      serverSide: true,
      responsive: true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/magazines/magazinesList",
        type: 'post',
        headers:this.adminHeaders
      },
      columns: [
        {data: 'id', name: 'id', searchable:false, visible: false},
        { data: "title", name: "title"},
        { data: 'url', name: 'url'},
        { data: null, orderable: false, searchable:false, render: (data) => {
            let btn = '';
            btn += `<a class="action-editing edit px-2" title="Edit" data-id="${data.id}" href="javascript:void(0);" ><i class="fas fa-edit mw-20"></i></a> `
            btn += `<a class="action-delete faq_trash delete px-2" title="Delete" data-id="${data.id}" href="javascript:void(0);" ><i class="fas fa-trash mw-20"></i></a>`
            return btn
          }
        }
      ],
      order: [[0, "desc"]],
      initComplete: function(settings, json) {
        self.loadCompleted = true;
      }
    });
    $(document).on("click", "table#magazineList tbody .edit" , function(e){
      self.showLoader = true;
      axios.get(self.basePath + 'api/magazines/show/'+$(this).data('id')
          , {
            headers:self.adminHeaders
          }).then(response => {
        if (response.data.status == 'success') {
          self.magazineErrors.clear();
          self.editModal.function = 'magazineEdit';
          self.editModal.title = 'Edit Magazine';
          self.magazine.title = response.data.data.title;
          self.magazine.url = response.data.data.url;
          self.magazine.id = response.data.data.id;
          self.showLoader = false;
          self.$root.$emit("bv::show::modal", "edit-modal");
        } else {
          console.log(response);
        }
      }).catch(error => {
        self.showLoader = false;
        self.errors = error.response.data.errors || {};
      });
    }).on("click", "table#magazineList tbody .delete" , function(e){
      self.$bvModal.msgBoxConfirm("Are you sure about this ?" ,{
        title: 'Delete',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        centered: false
      }).then(value => {
        if (String(value) == "true") {
          e.preventDefault();
          self.showLoader = true;
          axios.delete(self.basePath + 'api/magazines/delete/' + $(this).data('id')
              , {
                headers: self.adminHeaders
              }).then(response => {
            if (response.data.status == 'success') {
              self.showLoader = false;
              self.table.ajax.reload();
              self.displayAlert = true;
              self.alertMessage = "Successfully Deleted";
            } else {
              console.log(response);
            }
          }).catch(error => {
            self.showLoader = false;
            self.errors = error.response.data.errors || {};
          });
        }});
    })
  }
}
</script>